import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CertificationService from "@/modules/certification/services/CertificationService";

import type { Certificate } from "@/modules/certification/services/CertificationService.types";

import { useCertificationStore } from "@/modules/certification/store/CertificationStore";

export default class CertificationGuard {
  static async findLatestCertification(
    to: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const certificationId = Number(to.params.id);

    let creatorId: number;
    const { hasCertificates, queueCertificate, retrieveCertificate } =
      useCertificationStore();

    if (hasCertificates) {
      const certificate = retrieveCertificate() as Certificate;
      creatorId = certificate.creator_id;
    } else {
      const latestCertificate =
        await CertificationService.getLatestPendingApproval(certificationId);
      creatorId = latestCertificate.creator_id;
      //If we have a certificate, we store it
      queueCertificate(latestCertificate);
    }

    if (creatorId) {
      return next({
        name: "CertificationApproval",
        params: {
          id: certificationId,
          creatorId: creatorId,
        },
      });
    }

    return next({
      name: "PendingCertifications",
      params: {
        certificationId: certificationId,
      },
    });
  }
}
